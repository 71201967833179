import { FC, useEffect, useRef } from 'react'

import { API } from '@editorjs/editorjs'
import { JSONEditor, JSONEditorPropsOptional } from 'vanilla-jsoneditor'

import { handleEmbeddedEditorActions } from './commonUtils'

// React wrapper for the JSON Editor
const JSONEditorReact: FC<
  JSONEditorPropsOptional & {
    id?: string
    className?: string
    editorApi: API
    updateProps?: boolean
  }
> = ({ editorApi, updateProps, ...props }) => {
  const refContainer = useRef<HTMLDivElement>(null)
  const refEditor = useRef<JSONEditor | null>(null)

  useEffect(() => {
    // create editor
    refEditor.current = new JSONEditor({
      target: refContainer.current!,
      props: {},
    })

    // Prevent events bubbling up to the parent editor
    handleEmbeddedEditorActions(refContainer.current, editorApi)

    // Explicitly handle the paste event
    editorApi.listeners.on(refContainer.current, 'paste', (event) => {
      console.log('Paste event intercepted and stopped.')
      event.preventDefault() // Prevent default paste behavior
      event.stopPropagation() // Stop further propagation
      event.stopImmediatePropagation() // Stop all other paste listeners
    })

    // destroy editor
    return () => {
      if (refEditor.current) {
        refEditor.current.destroy()
        refEditor.current = null
      }
    }
  }, [])

  useEffect(() => {
    // update props
    if (refEditor.current) {
      refEditor.current?.updateProps(props)
    }
  }, [refEditor, updateProps])

  return <div className={props.className} ref={refContainer} />
}

export default JSONEditorReact
