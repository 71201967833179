import { KeyboardEventHandler } from 'react'

import type { DefaultInputProps } from '../types'
import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'

export const LabeledTextarea = ({
  controlled = true,
  item,
  itemName,
  updateItem,
  label,
  placeholder,
  rows = 3,
  disabled,
  inputClassName,
  customOnChange,
  onKeyDown,
  tooltip,
  style,
}: DefaultInputProps & {
  placeholder?: string
  rows?: number
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>
}) => {
  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <textarea
        id={itemName}
        rows={rows}
        name={label}
        placeholder={placeholder || `Enter ${itemName}...`}
        className={`form-control ${inputClassName}`}
        value={controlled ? item[itemName] : undefined}
        defaultValue={!controlled ? item[itemName] : undefined}
        onChange={(e) =>
          customOnChange ? customOnChange(e) : updateItem({ ...item, [itemName]: e.target.value })
        }
        onKeyDown={onKeyDown}
        disabled={disabled}
        style={style}
      />
    </FormGroup>
  )
}
