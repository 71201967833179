import * as React from 'react'

import { Arrow, Content, Provider, Root, Trigger } from '@radix-ui/react-tooltip'

export const TooltipVariants = {
  default: 'var(--primary)',
  warning: '#ff0000',
  success: 'var(--success)',
  notice: 'var(--warning)',
}

const DefaultToolTipStyles = {
  backgroundColor: 'white',
  color: 'black',
  zIndex: 99,
  border: '1px solid var(--primary)',
  boxShadow: 'rgba(0, 0, 0, 0.36) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
}

function getTooltipStyles(variant: string) {
  let styles: React.CSSProperties

  switch (variant) {
    case 'warning':
      styles = {
        ...DefaultToolTipStyles,
        color: TooltipVariants[variant],
        border: `1px solid ${TooltipVariants[variant]}`,
      }
      break
    case 'success':
      styles = {
        ...DefaultToolTipStyles,
        border: `1px solid ${TooltipVariants[variant]}`,
      }
      break
    case 'notice':
      styles = {
        ...DefaultToolTipStyles,
        border: `1px solid ${TooltipVariants[variant]}`,
      }
      break
    default:
      styles = DefaultToolTipStyles
      break
  }

  return styles
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content> & {
    width?: number | string
    variant?: string
  }
>(
  (
    {
      className = 'rounded p-3',
      side = 'top',
      sideOffset = 3,
      align = 'center',
      alignOffset = 0,
      width = 500,
      variant = 'default',
      ...props
    },
    ref
  ) => {
    return (
      <Content
        ref={ref}
        side={side}
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
        className={className}
        style={{ ...getTooltipStyles(variant), width: width }}
        {...props}
      />
    )
  }
)
TooltipContent.displayName = Content.displayName

type TooltipProps = {
  width?: string | number
  variant?: string
  tooltipTrigger: React.ReactNode
  tooltipContent: string | React.ReactNode
}

const Tooltip = ({
  className,
  side,
  sideOffset,
  align,
  alignOffset,
  width,
  tooltipTrigger,
  tooltipContent,
  variant = 'default',
}: TooltipProps & React.ComponentPropsWithoutRef<typeof Content>) => (
  <Provider delayDuration={150}>
    <Root>
      <Trigger asChild>{tooltipTrigger}</Trigger>
      <TooltipContent
        className={className}
        side={side}
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
        width={width}
        variant={variant}
      >
        <Arrow fill={TooltipVariants[variant]} />
        {tooltipContent}
      </TooltipContent>
    </Root>
  </Provider>
)

export default Tooltip
