import type { PropsWithChildren } from 'react'

import { Sidebar as PrimeReactSidebar } from 'primereact/sidebar'

interface SidebarProps extends PropsWithChildren {
  title: string | JSX.Element
  show: boolean
  closeClickHandler: () => void
}

const sidebarStyle = () => ({
  height: '100%',
  width: '100%',
  maxHeight: '100vh',
  maxWidth: '400px',
  borderRadius: 0,
  backgroundColor: '#fff',
})

// https://primereact.org/sidebar/
const Sidebar: React.FC<SidebarProps> = ({ title, show, closeClickHandler, children }) => {
  return (
    <PrimeReactSidebar
      visible={show}
      onHide={() => closeClickHandler()}
      style={{ ...sidebarStyle() }}
      blockScroll={true}
      dismissable={true}
      position="right"
      header={<h5 className="mb-0">{title}</h5>}
    >
      {children}
    </PrimeReactSidebar>
  )
}

export default Sidebar
