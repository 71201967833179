import type { PropsWithChildren } from 'react'

import type { ItemProps } from '../types'
import { ActionButtons } from './ActionButtons'

export const ItemOption = ({
  index,
  item,
  title,
  edit,
  setEdit,
  deleteItem,
  moveUp,
  hidePreview = false,
  className,
  children,
}: {
  index: number
  item: ItemProps
  title: string
  edit: boolean
  setEdit: (edit: boolean) => void
  deleteItem?: (value: ItemProps) => void
  moveUp?: (index: number) => void
  hidePreview?: boolean
  className?: string
} & PropsWithChildren) => {
  return (
    <div className={`border rounded bg-white pt-3 px-3 ${className}`}>
      <div className="row justify-content-center mb-2">
        <div className="col-sm-6 col-md-4 d-flex align-items-start">
          <h6 className="text-left text-muted">
            {title.toUpperCase()} {index + 1}
          </h6>
        </div>
        <div className="col-sm-6 col-md-8 d-flex justify-content-end">
          <ActionButtons
            index={index}
            item={item}
            edit={edit}
            setEdit={setEdit}
            deleteItem={deleteItem}
            moveUp={moveUp}
            hidePreview={hidePreview}
          />
        </div>
      </div>
      {children}
    </div>
  )
}
