import { Dispatch, SetStateAction, useState } from 'react'

import type { DefaultInputProps, SelectOption } from '../types'
import { FormGroup } from './FormGroup'
import { Cross } from './Icons'
import InputLabel from './InputLabel'

export const LabeledMultipleSelectInput = ({
  item,
  itemName,
  updateItem,
  label,
  placeholder,
  selectOptions,
  minOptions,
  customAdd,
  customDelete,
  inputClassName,
  tooltip,
}: DefaultInputProps & {
  placeholder?: string
  selectOptions: SelectOption[]
  minOptions?: number
  customAdd?: (
    options: (string | number)[],
    itemName: string,
    currentInput: string | number,
    setCurrentInput: Dispatch<SetStateAction<string | number>>,
    setOptions: Dispatch<SetStateAction<(string | number)[]>>
  ) => void
  customDelete?: (
    options: (string | number)[],
    itemName: string,
    option: string | number,
    setOptions: Dispatch<SetStateAction<(string | number)[]>>
  ) => void
}) => {
  const [selectedOption, setSelectedOption] = useState('')
  const [options, setOptions] = useState<(string | number)[]>(item[itemName] || [])

  const handleAdd = () => {
    if (options.includes(selectedOption)) {
      alert('You have already added ' + selectedOption)
    } else if (selectedOption.length > 0) {
      setOptions([...options, selectedOption])
      updateItem({ ...item, [itemName]: [...options, selectedOption] })
      setSelectedOption('')
    }
  }

  const handleDelete = (option: string | number) => {
    const filteredOptions = options.filter((o) => o !== option)
    setOptions(filteredOptions)
    updateItem({ ...item, [itemName]: filteredOptions })
  }

  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <div className="d-flex">
        <select
          id={itemName}
          className={`form-control ${inputClassName}`}
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="" disabled>
            {placeholder || 'Select an option'}
          </option>
          {selectOptions?.map((option, index) => (
            <option key={`${option.label} ${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <button
          onClick={() =>
            customAdd
              ? customAdd(options, itemName, selectedOption, setSelectedOption, setOptions)
              : handleAdd()
          }
          className="btn btn-sm btn-primary ml-2"
        >
          Add
        </button>
      </div>
      <div className="d-flex align-items-center mt-2 flex-wrap" style={{ gap: '0.25rem' }}>
        {options?.map((option, id) => (
          <span
            key={`input-item-${id}`}
            className="bg-light d-flex align-items-center py-1 px-2"
            style={{ borderRadius: 24 }}
          >
            {selectOptions.filter((select) => `${select.value}` === option)[0]?.label}
            <div
              className={`delete-btn ml-1 ${minOptions && options.length <= minOptions ? 'd-none' : 'd-flex'}`}
              onClick={() =>
                customDelete
                  ? customDelete(options, itemName, option, setOptions)
                  : handleDelete(option)
              }
            >
              <Cross size={15} color="currentColor" />
            </div>
          </span>
        ))}
      </div>
    </FormGroup>
  )
}
