import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { DirectUpload } from '@rails/activestorage'

import type { LocationProps } from '../types'

// Check if a color is dark
export function isDarkColor(color: string) {
  const hexColor = color.replace('#', '')

  // Convert hex color to RGB
  const r = parseInt(hexColor.slice(1, 3), 16)
  const g = parseInt(hexColor.slice(3, 5), 16)
  const b = parseInt(hexColor.slice(5, 7), 16)

  // Calculate luminance (brightness) using the formula
  // L = 0.299 * R + 0.587 * G + 0.114 * B
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b

  // Set a threshold value (adjust as needed)
  const threshold = 140

  return luminance < threshold
}

// Function to validate a hex color code
export function isValidHexColor(hex: string) {
  const hexRegex = /^#([0-9A-Fa-f]{6})$/
  return hexRegex.test(hex)
}

// Function to validate hex, RGB, and RGBA color codes
export function isValidColor(color: string) {
  const hexRegex = /^#([0-9A-Fa-f]{6})$/
  const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/
  const rgbaRegex = /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0|1|0?\.\d+)\)$/

  return hexRegex.test(color) || rgbRegex.test(color) || rgbaRegex.test(color)
}

// Apply styling to the holder element based on the block tunes data
export function initialiseStyling(holder: HTMLElement, data: any) {
  if (!holder || !data) {
    return
  }

  const screenType =
    window.innerWidth < 768 ? 'mobile' : window.innerWidth < 992 ? 'tablet' : 'desktop'

  // Contain
  if (data.container && data.container.contain) {
    holder.classList.add('px-10')
  }

  // Classname
  if (data.classname && data.classname.className) {
    const newClasses = data.classname.className.split(' ')
    newClasses.forEach((className) => {
      if (className.trim() !== '') {
        holder.classList.add(className)
      }
    })
  }

  // Margin
  if (data.margin) {
    const marginType = data.margin
    screenType === 'mobile'
      ? data.margin?.mobile
      : screenType === 'tablet'
        ? data.margin?.tablet
        : data.margin
    holder.style.marginTop = `${marginType?.spacingTop * 0.5}rem` // Adjust as needed
    holder.style.marginBottom = `${marginType?.spacingBottom * 0.5}rem` // Adjust as needed
  }

  // Padding
  if (data.padding) {
    const paddingType = data.padding
    screenType === 'mobile'
      ? data.padding?.mobile
      : screenType === 'tablet'
        ? data.padding?.tablet
        : data.padding
    holder.style.paddingTop = `${paddingType?.paddingTop * 0.5}rem` // Adjust as needed
    holder.style.paddingBottom = `${paddingType?.paddingBottom * 0.5}rem` // Adjust as needed
  }

  // Background Colour
  if (data.backgroundColor) {
    holder.style.backgroundColor = `${
      data.backgroundColor.backgroundColor !== 'custom'
        ? `var(--${data.backgroundColor.backgroundColor})`
        : data.backgroundColor.customBackgroundColor
    }`
  }

  // Text Colour
  if (data.textColor) {
    holder.style.color = `${
      data.textColor.textColor !== 'custom'
        ? `var(--${data.textColor.textColor})`
        : data.textColor.customTextColor
    }`
  }
}

// Convert a string to a parameterised string (e.g. "Hello World" -> "hello-world")
export function parameterize(string: string) {
  if (!string) return ''
  return string
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '')
}

// Convert a parameterised string to a readable string (e.g. "hello-world" -> "Hello World")
// ? Duplicate of humanize
export function deparameterize(string: string) {
  if (!string) return ''
  return string
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// Capitalize the first letter of a string
export function capitalize(string: string) {
  if (typeof string !== 'string') return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// Convert a string to a human readable string (e.g. "hello-world" -> "Hello World")
// ? Duplicate of deparameterize
export function humanize(string: string) {
  if (typeof string !== 'string') return string
  return string
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/\-/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase()
    })
}

// Convert an array to a sentence (e.g. ["Hello", "World"] -> "Hello and World")
export function toSentence(arr: string[]) {
  if (arr?.length === 0 || !arr) {
    return undefined
  }
  if (arr?.length === 1) {
    return arr[0]
  }
  return arr.slice(0, arr.length - 1).join(', ') + ', and ' + arr.slice(-1)
}

//! Convert usages of this to filterUniqueItems
export function removeDuplicateLocations(locations: LocationProps[]) {
  const seenLocationIds = new Set()
  return locations?.filter((item) => {
    const locationId = item.id
    if (!seenLocationIds.has(locationId)) {
      seenLocationIds.add(locationId)
      return true
    }
    return false
  })
}

// Filter an array of objects to remove duplicates based on a key
export function filterUniqueItems<T>(arr: T[], key: string) {
  const seenIds = new Set()
  return arr?.filter((item) => {
    const keys = key.split('.')
    let value = item
    for (const k of keys) {
      if (value && value.hasOwnProperty(k)) {
        value = value[k]
      } else {
        // Handle missing keys gracefully
        return false
      }
    }
    if (!seenIds.has(value)) {
      seenIds.add(value)
      return true
    }
    return false
  })
}

export function generateRandomId(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomId = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    randomId += characters.charAt(randomIndex)
  }

  return randomId
}

// Convert a number to a currency string (e.g. 1000 -> "$1,000")
export function toCurrency(value: number) {
  return `$${value === null ? 0 : value?.toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, '$1,')}`
}

/** Remove HTML entities so that tags like `<sup>` work */
export function cleanHtml(text: string, websiteName: string) {
  return !text
    ? ''
    : text
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/\{website\.name\}/g, websiteName)
  // ! These will need to be added eventually
  // .replace(/\{website\.city\}/g, mockWebsite ? mockWebsite : WEBSITE.city)
  // .replace(/\{website\.state\}/g, mockWebsite ? mockWebsite : WEBSITE.state)
}

// Render an edit settings button in the block's settings menu
export function renderEditSettingsButton(uniqueId: string) {
  const editButton = document.createElement('div')
  editButton.className = 'ce-popover-item w-100'

  editButton.innerHTML = `
     <div id="edit_settings_btn" class="d-flex flex-row align-items-center">
      <div class="ce-popover-item__icon">
        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
        </svg>
      </div>
      <div class="ce-popover-item__title">Edit Settings</div>
     </div>
     `

  editButton.addEventListener('click', () => {
    // Get the modal button element by its unique ID
    const button = document.getElementById(`Modal${uniqueId}`)

    // Check if the button element exists in the DOM
    if (button) {
      // Close the open tunes menu
      const openPopover = document.getElementsByClassName('ce-popover--opened')[0]
      if (openPopover) {
        openPopover.classList.remove('ce-popover--opened')
      }

      // Trigger a click on the hidden modal button
      button.click()
    }
  })

  return editButton
}

// Render a hidden modal button used to open the modal via the edit settings button
export function renderHiddenModalButton(uniqueId: string, setShow: (value: boolean) => void) {
  return (
    <button id={`Modal${uniqueId}`} className="d-none" onClick={() => setShow(true)}>
      Open Modal
    </button>
  )
}

/**
 * Changes z-index of the embedded container to avoid issues with content overlaying the modal.
 * ! This is very hacky, I'm hoping to rework it when I rework the embedded editor tools.
 */
export function handleEmbeddedModal(uniqueId: string, show: boolean) {
  const modal = document.getElementById(`Modal${uniqueId}`)
  const embeddedContainer: HTMLElement | null = modal ? modal.closest('.embedded-container') : null

  if (embeddedContainer) {
    if (show) {
      embeddedContainer.style.zIndex = '2'
    } else {
      // Delay setting the z-index back to 1 to avoid issues with the content overlaying the modal
      setTimeout(() => {
        embeddedContainer.style.zIndex = '1'
      }, 500)
    }
  }
}

// There was an issue with >= getting translated to &gt; in the HTML
// The below snippet handles converting that BACK to >=
export function htmlDecode(input: string) {
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

// Handle keydown events for embedded editor tools
export function handleEmbeddedEditorActions(container: HTMLElement, block: any) {
  if (container) {
    // Disable propagation of keydown events to avoid conflicts with the parent editor
    block.listeners.on(container, 'keydown', (event: Event) => {
      event.stopPropagation()
    })
  }
}

// Get the current screen type (mobile, tablet, desktop)
export function useResponsiveScreen() {
  const [screenType, setScreenType] = useState(
    window.innerWidth < 768 ? 'mobile' : window.innerWidth < 992 ? 'tablet' : 'desktop'
  )

  useEffect(() => {
    const handleResize = () => {
      setScreenType(
        window.innerWidth < 768 ? 'mobile' : window.innerWidth < 992 ? 'tablet' : 'desktop'
      )
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return screenType
}

// Render tabs for tool settings modals
export function renderTabs(
  stepData: any[],
  activeIndexState: { activeIndex: number; previousActiveIndex: number },
  setActiveIndexState: Dispatch<
    SetStateAction<{ activeIndex: number; previousActiveIndex: number }>
  >,
  availableSteps: number[],
  customButton?: React.ReactNode
) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="pb-2 border-bottom d-flex w-100">
          {/* Desktop Tabs */}
          <div className="d-none d-md-flex flex-row flex-wrap w-100">
            {stepData.map((tab, index) => (
              <button
                key={`tab-${tab.name}-${index}`}
                className={`btn btn-${
                  activeIndexState?.activeIndex === index ? 'primary' : 'light'
                } mb-2`}
                style={{
                  width: `calc(100% / ${stepData.length})`,
                  minWidth: `calc(100% / 8)`,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => {
                  setActiveIndexState({
                    activeIndex: index,
                    previousActiveIndex: activeIndexState.activeIndex,
                  })
                }}
                disabled={availableSteps ? !availableSteps.includes(index) : false}
              >
                <span>{tab.name}</span>
              </button>
            ))}
          </div>
          <div className="d-flex align-items-start">{customButton && customButton}</div>
          {/* Mobile Dropdown */}
          <div className="d-block d-md-none w-100">
            <label htmlFor="step-select">Component Settings</label>
            <div className="d-flex flex-row">
              <select
                className="form-control d-flex flex-grow-1 d-md-none"
                id="step-select"
                defaultValue={activeIndexState.activeIndex ?? 0}
                onChange={(e) => {
                  setActiveIndexState({
                    activeIndex: parseInt(e?.target?.value),
                    previousActiveIndex: activeIndexState.activeIndex,
                  })
                }}
              >
                {stepData.map((tab, index) => (
                  <option key={`${tab?.name}-${index}`} value={index}>
                    {tab.name}
                  </option>
                ))}
              </select>
              {customButton && customButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Handles rendering of next/previous buttons for the settings modal
export function renderStepButtons(
  activeIndexState: { activeIndex: number; previousActiveIndex: number },
  setActiveIndexState: Dispatch<
    SetStateAction<{ activeIndex: number; previousActiveIndex: number }>
  >,
  steps: any[],
  setShow: (value: boolean) => void,
  customNextStepHandling?: (activeIndex: number) => boolean
) {
  return (
    <div className="d-flex mt-3">
      {/* Previous */}
      {activeIndexState?.activeIndex !== 0 && (
        <button
          className="btn btn-primary ml-0 mr-auto"
          onClick={() => {
            setActiveIndexState({
              activeIndex: activeIndexState.activeIndex - 1,
              previousActiveIndex: activeIndexState.activeIndex,
            })
          }}
        >
          Previous
        </button>
      )}
      {/* Next/Done */}
      <button
        className="btn btn-primary ml-auto mr-0"
        onClick={() => {
          if (customNextStepHandling) {
            if (!customNextStepHandling(activeIndexState?.activeIndex)) return // Don't allow step change
          }
          // Close Modal if last step
          if (activeIndexState?.activeIndex === steps.length - 1) {
            setShow(false)
            return // Don't increase index
          }
          setActiveIndexState({
            activeIndex: activeIndexState.activeIndex + 1,
            previousActiveIndex: activeIndexState.activeIndex,
          })
        }}
      >
        {activeIndexState?.activeIndex === steps.length - 1 ? 'Close' : 'Next'}
      </button>
    </div>
  )
}

// This function hits the direct_upload_path to return a remote URL
// Which is passed back to the client and is used to upload the asset directly to the
// third party storage (in this case cloudinary).
// Once the file has been uploaded to cloudinary, an additional call is made to the server
// to get the asset URL AND link the asset back to the page via the cloudinary_images table
export function uploadFile(file: Blob) {
  const url = '/direct_upload_path'
  const upload = new DirectUpload(file, url)
  let imageUrl = document.getElementById('editorjs').getAttribute('imageUrl')

  return new Promise(function (resolve, reject) {
    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        fetch(`${imageUrl}?signed_id=${blob.signed_id}`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((res) => res.json())
          .then((res) => {
            resolve({
              success: 1,
              file: {
                url: res.file.url,
                width: res.file.width,
                height: res.file.height,
              },
            })
          })
      }
    })
  })
}
