import type { DefaultInputProps } from '../types'
import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'
import { generateRandomId } from './commonUtils'

export const LabeledCheckbox = ({
  controlled = true,
  item,
  itemName,
  updateItem,
  label,
  customValue,
  disabled = false,
  customOnChange,
  tooltip,
}: DefaultInputProps & { customValue?: boolean }) => {
  // Give each instance a unique id
  const id = itemName + generateRandomId(3)

  return (
    <FormGroup>
      <input
        id={id}
        type="checkbox"
        className="form-check-input"
        checked={!!customValue ? customValue : controlled ? !!item[itemName] : undefined}
        defaultChecked={!controlled ? item[itemName] : undefined}
        onChange={(e) => {
          customOnChange ? customOnChange(e) : updateItem({ ...item, [itemName]: e.target.checked })
        }}
        disabled={disabled}
      />
      <InputLabel label={label} htmlFor={id} itemName={itemName} tooltip={tooltip} />
    </FormGroup>
  )
}
