import type { ItemProps } from '../types'
import { ArrowUp, Cross, Edit, Eye } from './Icons'
import Tooltip from './Tooltip'

/**
 * React hook used for managing state for tools with multiple items,
 * such as FAQ Section, Cards Section, and the Form Builder.
 */
export const useActions = ({
  items,
  defaultItem,
  syncedUpdate,
}: {
  items: ItemProps[]
  defaultItem: () => ItemProps
  syncedUpdate: (items: ItemProps[]) => void
}) => {
  const addItem = () => {
    syncedUpdate([...items, defaultItem()])
  }

  const updateItem = (item: ItemProps) => {
    const updatedItems = items.map((i) => {
      return i.id === item.id ? item : i
    })
    syncedUpdate([...updatedItems])
  }

  const deleteItem = (item: ItemProps) => {
    const updatedItems = items.filter((i) => i.id !== item.id)
    syncedUpdate(updatedItems)
  }

  const moveUp = (index: number) => {
    setTimeout(() => {
      if (index > 0) {
        const updatedItems = [...items]
        updatedItems.splice(index - 1, 0, updatedItems.splice(index, 1)[0])
        syncedUpdate(updatedItems)
      }
    }, 300)
  }

  return {
    addItem,
    updateItem,
    deleteItem,
    moveUp,
  }
}

export const ActionButtons = ({
  index,
  item,
  edit,
  setEdit,
  deleteItem,
  moveUp,
  hidePreview = false,
}: {
  index: number
  item: ItemProps
  edit: boolean
  setEdit: (edit: boolean) => void
  deleteItem?: (value: ItemProps) => void
  moveUp?: (index: number) => void
  hidePreview?: boolean
}) => {
  return (
    <>
      {index > 0 && moveUp && (
        <Tooltip
          tooltipTrigger={
            <button
              className="d-flex align-items-center btn btn-sm btn-dark ml-1"
              onClick={() => moveUp(index)}
            >
              <ArrowUp size="20px" color="white" />
            </button>
          }
          tooltipContent={
            <div className="small">
              <p className="mb-0">Use this to move the item up in the list of items.</p>
            </div>
          }
          width={200}
        />
      )}
      {deleteItem && (
        <Tooltip
          tooltipTrigger={
            <button
              className="d-flex align-items-center btn btn-sm btn-danger ml-1"
              onClick={() => deleteItem(item)}
            >
              <Cross size="20px" color="white" />
            </button>
          }
          tooltipContent={
            <div className="small">
              <p className="mb-0">Use this to delete the item.</p>
            </div>
          }
          width={200}
        />
      )}
      <Tooltip
        tooltipTrigger={
          edit ? (
            <button
              className={`align-items-center btn btn-sm btn-secondary ml-1 ${
                hidePreview ? 'd-none' : 'd-flex'
              }`}
              onClick={() => setEdit(false)}
            >
              <Eye size="20px" color="white" />
            </button>
          ) : (
            <button
              className={`align-items-center btn btn-sm btn-secondary mx-1 ${
                hidePreview ? 'd-none' : 'd-flex'
              }`}
              onClick={() => setEdit(true)}
            >
              <Edit size="20px" color="white" />
            </button>
          )
        }
        tooltipContent={
          <div className="small">
            <p className="mb-0">Use this to switch between editing and previewing the item.</p>
          </div>
        }
        width={200}
      />
    </>
  )
}
